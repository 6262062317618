const HeaderStore = {
    state: {
        visible: false,
    },
    mutations: {

    },
    actions: {

    },
    getters: {

    }
};

export default HeaderStore;