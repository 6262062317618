import ready from '@ryanmorr/ready';

(() => {
    ready('[data-custom-scrollbar]', (element: HTMLElement) => {
        import('./customScrollbar').then((CustomScrollbar: any) => {
            const options = element.dataset.customScrollbarOptions;
            new CustomScrollbar.default(
                element,
                options ? JSON.parse(options) : {},
            );
        });
    });
})();
