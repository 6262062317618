const VueScrollTo = require('vue-scrollto');
import Vue from 'vue';
import Alpine from 'alpinejs';
import './plugins/element.js';
import '@/styles/main.scss';
import 'element-ui/lib/theme-chalk/popover.css';
import { Popover } from "element-ui";




/* Auto Imports */
const srcContext = require.context(
    '@',
    true,
    /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
    '@root/templates',
    true,
    /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);

Alpine.start();

Vue.use(VueScrollTo);
Vue.directive('prevent-default', {
    bind: (el) => {
        el.addEventListener("click", (event) => {
           event.preventDefault();
        });
    }
});
// Set Available Components
Vue.component("el-popover", Popover);
Vue.config.productionTip = false;

// Static Files
import '@/assets/images/event-salon1.png';
import '@/assets/images/event-table-ronde.png';
import '@/assets/images/event-webinair-2020.jpg';
import '@/assets/images/img_communaute2.jpg';
import '@/assets/images/img_communaute3.jpg';
import '@/assets/images/img_offreur3.jpg';
import '@/assets/images/img_home.jpg';
import '@/assets/images/img_communaute1.jpg';
import '@/assets/images/img_offreurs1.jpg';
import '@/assets/images/img_offreur3.jpg';
import '@/assets/images/img_nos_membres.jpg';
import '@/assets/images/img_actualites.jpg';
import '@/assets/images/img_industries.jpg';
import '@/assets/images/img_actions.jpg';
import '@/assets/images/img_contact.jpg';
import '@/assets/images/img_industries1.jpg';
import '@/assets/images/img_industries2.jpg';
import '@/assets/images/img_industries3.jpg';
import '@/assets/images/img_industries4.jpg';
import '@/assets/images/img_industries5.jpg';
import '@/assets/images/img_industries6.jpg';
import '@/assets/images/img_industries7.jpg';
import '@/assets/images/img_webinar1.jpg';
import '@/assets/images/img_webinar2.jpg';
import '@/assets/images/img_webinar3.jpg';
import '@/assets/images/img_webinar4.jpg';
import '@/assets/images/Particules_droite.svg';
import '@/assets/images/Particules_gauche.svg';
import '@/assets/images/Particules_footer.svg';
import '@/assets/images/Particules_volantes1.svg';
import '@/assets/images/Particules_volantes2.svg';
import '@/assets/images/logo_v2_horizontal.svg';
import '@/assets/images/logo_v2_vertical.png';
import '@/assets/images/img_finannee.jpg';
import '@/assets/images/edf_pulse_grand_est.png';
import '@/assets/images/img_sepem.jpg';
import '@/assets/images/img_presentation_concours_edf_pulse.png';
import '@/assets/images/img_usine_connectee_retex.png';
import '@/assets/images/img_osez_transformation_industrie.png';
import '@/assets/images/img_decarbonisation_penurie.png';
import '@/assets/images/img_offreurs_partenaires_engages_sepem.png';
import '@/assets/images/voyage_au_pays_de_la_data.jpg';
import '@/assets/images/sortez_l_imprimante_3d.jpg';
import '@/assets/images/event_bonne_annee_2023.jpg';
import '@/assets/images/event_transformation_indus_feminin.png';
import '@/assets/images/event_meet_up_4_0.png';
import '@/assets/images/maitrisez.jpg';
import '@/assets/images/quelle_valeur_ajoute.jpg';
import '@/assets/images/event_decarbonation.png';
import '@/assets/images/event_industrie_futur.png';
