declare const $clampify: any;

import ready from '@ryanmorr/ready';

const init = () => {
    ready('[data-clampify]', (element: HTMLElement) => {
        import('clampify').then((asyncModule: any) => {
            const defaultOptions = {
                autoUpdate: true,
                maxLines: 3,
            };
            $clampify(
                element,
                element.dataset.clampify
                    ? {
                        ...defaultOptions,
                        ...JSON.parse(element.dataset.clampify),
                    }
                    : defaultOptions,
            );
        });
    });
};

window.addEventListener('load', init);
