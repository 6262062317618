import ready from '@ryanmorr/ready';
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles


ready('[data-ref="slider"]', (element: HTMLElement) => {
  const options = JSON.parse(element.getAttribute('data-options') || '{}');

  const swiper = new Swiper(element, {
    ...options,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    modules: [Navigation, Pagination],
  });
});
