import Alpine from 'alpinejs';
import { Loader } from '@googlemaps/js-api-loader';
import { v4 as uuid } from 'uuid';

Alpine.data('mapInstance', function ({ locations }: any) {
    return {
        instance: null,
        uuid: uuid(),
        locations,
        activeLocation: {
            name: '',
            company: '',
            description: '',
            address: '',
            lat: 0,
            lng: 0,
            zone: '',
            landlineTel: '',
            isReferent: false,
        },
        showLocationInfos: false,
        defaultZoom: 8,
        init() {
            const loader = new Loader({
                apiKey: process.env.APP_GOOGLE_API_KEY,
                version: 'weekly',
            });
            loader.load().then(() => {
                this.createMap();
            });
        },
        createMap() {
            const mapStyles: any = [
                {
                    "featureType": "administrative",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": "-100"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 65
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": "50"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": "-100"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [
                        {
                            "lightness": "30"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [
                        {
                            "lightness": "40"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "hue": "#ffff00"
                        },
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -97
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -100
                        }
                    ]
                }
            ];
            const mapOptions = {
                center: {
                    lat: 48.65,
                    lng: 5,
                },
                zoom: this.defaultZoom,
                styles: mapStyles,
            };
            this.instance = new google.maps.Map(
                (this as any).$refs.mapContainer,
                mapOptions,
            );
            const map = this.instance;
            this.locations.forEach((location: any) => {
                const image = {
                    url: location.isReferent ? require('@/assets/images/map_marker.png') : require('@/assets/images/map_marker_classic.png'),
                    size: new google.maps.Size(32, 32),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(16, 16),
                };
                const marker = new google.maps.Marker({
                    position: {
                        lat: Number(location.lat),
                        lng: Number(location.lng),
                    },
                    title: location.company,
                    icon: image,
                    animation: google.maps.Animation.DROP,
                    map,
                    // @ts-ignore
                    location,
                });
                this.onMarkerClick(marker, map);
            });
        },
        onCardClose() {
            this.showLocationInfos = false;
            // (this as any).instance.setZoom(this.defaultZoom);
        },
        onMarkerClick(marker: any, map: any) {
            marker.addListener('click', () => {
                map.setZoom(14);
                map.setCenter(marker.getPosition());
                this.activeLocation = marker.location;
                this.showLocationInfos = true;
            });
        },
        get cardName() {
            if (this.activeLocation.isReferent && this.activeLocation.zone) return `Votre référent ${this.activeLocation.zone}`;
            return this.activeLocation.company;
        }
    };
});
