import Vue from 'vue';
import Vuex from 'vuex';
import HeaderStore from '@/store/header.store.ts';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        header: HeaderStore
    }
});
