import ready from '@ryanmorr/ready';

const selector = '[data-upload]';

(() => {
    ready(selector, (element: any) => {
        import('./upload').then((Upload: any) => {
            new Upload.default(element);
        });
    });
})();
