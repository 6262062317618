import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import { getUrlParamByName, removeUrlParamByName } from '@/utils/app';

Alpine.plugin(persist);

Alpine.data('contactForms', function () {
    return {
        // @ts-ignore
        selectedFormId: this.$persist(1),

        init() {
            const formId = getUrlParamByName('formId');
            if (formId) {
                this.switchForm(Number(formId));
                removeUrlParamByName('formId');
            }
        },

        switchForm(id: number) {
            this.selectedFormId = id;
        },
    };
});

Alpine.data('memberContactForm', function () {
    return {
        isCertifiedChecked: true,
        isCertifiedCheckboxEl: null,
        isNotCertifiedCheckboxEl: null,

        init() {
            this.isCertifiedCheckboxEl = (this as any).$refs.certifiedCheckbox;
            this.isNotCertifiedCheckboxEl = (this as any).$refs.notCertifiedCheckbox;
            if (this.isCertifiedCheckboxEl && this.isNotCertifiedCheckboxEl) {
                (this as any).isCertifiedCheckboxEl.addEventListener('change', (e: any) => {
                    this.isCertifiedChecked = !!e.target.checked;
                    (this as any).isNotCertifiedCheckboxEl.checked = !!!e.target.checked;
                });
                (this as any).isNotCertifiedCheckboxEl.addEventListener('change', (e: any) => {
                    this.isCertifiedChecked = !!!e.target.checked;
                    (this as any).isCertifiedCheckboxEl.checked = !!!e.target.checked;
                });
            }
        },
    };
});
