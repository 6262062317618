import Vue from 'vue';
import Header from './Header.vue';
import store from '@/store';

const selector = '[data-vc="header"]';

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        store,
        components: {
            'vue-header': Header,
        },
        delimiters: ["<%", "%>"],
    }).$mount(selector);
});
