




import {
    Vue,
    Component,
    Watch,
    Prop
} from "vue-property-decorator";

@Component
export default class Header extends Vue {
    private mini: boolean = false;
    private headerHeight: number = 0;
    private headerHeightOffset: number = 150;
    private isMounted: boolean = false;
    private visible: boolean = false;
    public created() {

    }

    public mounted() {
        this.headerHeight = (this as any).$refs.header.offsetHeight;
        this.bindListeners();
        this.triggerScroll();
        this.isMounted = true;
        this.positionHash();
    }

    public positionHash() {
        if(window.location.hash && document.querySelector(window.location.hash)) {
            setTimeout(() => {
                window.scrollTo(0, (document.querySelector(window.location.hash) as any).getBoundingClientRect().top + window.scrollY - this.headerHeightOffset);
            });
        }
    }

    public triggerScroll() {
        let event;
        event = window.document.createEvent('UIEvents');
        event.initUIEvent('scroll', true, false, window, 0);
        window.dispatchEvent(event);
    }

    public bindListeners() {
        window.addEventListener('scroll', this.onScroll);
    }

    public onScroll(event: any) {
        const scroll = window.scrollY || (document.documentElement as any).scrollTop;
        this.mini = scroll >= this.headerHeight;
    }

    public onResize(event: any) {
        this.headerHeight = (this as any).$refs.header.innerHeight;
    }

    public beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onResize);
    }

    public destroyed() {

    }
}
